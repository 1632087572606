import React from 'react'
import './style.scss'
import { injectIntl, Link, FormattedMessage } from "gatsby-plugin-intl"
import Button from 'react-bootstrap/Button'
import { navigate } from 'gatsby-link'

function encode(data) {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&')
  }

  export default class Contact extends React.Component {
    constructor(props) {
      super(props)
      this.state = { isValidated: false }
    }
  
    handleChange = e => {
      this.setState({ [e.target.name]: e.target.value })
    }
  
    handleSubmit = e => {
      e.preventDefault()
      const form = e.target
      fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({
          'form-name': form.getAttribute('name'),
          ...this.state,
        }),
      })
        .then(() => navigate(form.getAttribute('action')))
        .catch(error => alert(error))
    }
  
    render() {
      return (

    // const Contact = ({ intl }) => {
  
    // return (
    <section id="contact">
        <div className="inner">
            <section>
            <form
                name="contact"
                method="post"
                action= "/success" 
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                onSubmit={this.handleSubmit}
              >
                {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                <input type="hidden" name="form-name" value="contact" />

                <div className="field">
                        <label className="title" htmlFor="name"><FormattedMessage id="c_preferred_name" /></label>
                        <input type="text" name="name" id="name" 
                        onChange={this.handleChange} required />
                    </div>
                    <div className = "nameFieldWrapper">
                        <p><FormattedMessage id="c_name" /></p>
                        <div className="field nameField">
                            <input type="text" name="firstName" id="firstName" 
                            onChange={this.handleChange} required/>
                            <label className="title nameLabel" htmlFor="firstName"><FormattedMessage id="c_first_name" /></label>
                        </div>
                        <div className="field nameField"> 
                            <input type="text" name="lastName" id="lastName" 
                            onChange={this.handleChange} required/>
                            <label className="title nameLabel" htmlFor="lastName"><FormattedMessage id="c_last_name" /></label>
                        </div>

                    </div>

                    <div className="field">
                        <label className="title" htmlFor="phone"><FormattedMessage id="c_phone" /></label>
                        <p className="description"><FormattedMessage id="c_phone_d" /></p>
                        <input type="text" name="phone" id="phone" 
                        onChange={this.handleChange} required/>
                    </div>
                    <div className="field">
                        <label className="title" htmlFor="email"><FormattedMessage id="c_email" /></label>
                        <input type="email" name="email" id="email" 
                        onChange={this.handleChange} required/>
                    </div>
                    <div className="field">
                        <label className="title" htmlFor="email"><FormattedMessage id="c_18_name" /></label>
                        <input type="text" name="name2" id="name2" 
                        onChange={this.handleChange} />
                    </div>
                    <div className="field">
                        <label className="title" htmlFor="email"><FormattedMessage id="c_18_email" /></label>
                        <input type="text" name="email2" id="email2" 
                        onChange={this.handleChange} />
                    </div>

                        <div id="contactOption"> 
                            
                            <label className="title" htmlFor="contactOption"><FormattedMessage id="c_contact_option" /></label>
                            
                            <div className="option">
                                <label>
                                <input type="radio" name="contactOption" value="Phone call" 
                                    onChange={this.handleChange}>
                                </input>
                                <FormattedMessage id="c_contact_phone" />
                                </label>
                            </div>
                            
                            <div className="option">
                                <input type="radio" name="contactOption" value="Email" 
                                onChange={this.handleChange}>
                                </input>
                                <label> <FormattedMessage id="c_contact_email" />
                                </label>
                            </div>
                        </div>

                        <hr className="custom_hr" style={{marginTop:'5%'}}/>

                    <div className="field">
                        <label className="title" htmlFor="goals"><FormattedMessage id="c_goals" /></label>
                        <p className="description"><FormattedMessage id="c_goals_p" /></p>
                        <textarea type="text" name="goals" id="goals" rows="6" 
                        onChange={this.handleChange}></textarea>
                    </div>
                    <div className="field">
                        <label className="title" htmlFor="info"><FormattedMessage id="c_info" /></label>
                        <p className="description"><FormattedMessage id="c_info_p" /></p>
                        <textarea name="info" id="info" rows="6" 
                        onChange={this.handleChange}></textarea>
                    </div>

                <div id="contactButton" className="d-flex flex-row justify-content-center">
                  <button className="mt-3 mb-5 warning warningCustom is-link" type="submit">
                  <FormattedMessage id="button_submit" />   
                  </button>
                </div>

              </form>
            </section>
 
        </div>
                    
    </section>

        )
    }
  }

//export default injectIntl(Contact)

           /* <section className="split">
                <section>
                    <div className="contact-method">
                        <span className="icon alt fa-envelope"></span>
                        <h3>Email</h3>
                        <a href="#">information@untitled.tld</a>
                    </div>
                </section>
                <section>
                    <div className="contact-method">
                        <span className="icon alt fa-phone"></span>
                        <h3>Phone</h3>
                        <span>(000) 000-0000 x12387</span>
                    </div>
                </section>
                <section>
                    <div className="contact-method">
                        <span className="icon alt fa-home"></span>
                        <h3>Address</h3>
                        <span>1234 Somewhere Road #5432<br />
                        Nashville, TN 00000<br />
                        United States of America</span>
                    </div>
                </section>
            </section> */
            
