import React, { Component } from 'react'
import { Map, Marker, InfoWindow, GoogleApiWrapper } from 'google-maps-react'
const styles = require('./styles.json')

class googleMap extends Component {

   state = {
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},
    };  

   onMarkerClick = (props, marker, e) =>
   this.setState({
     selectedPlace: props,
     activeMarker: marker,
     showingInfoWindow: true
   });
   onMapClicked = (props) => {
      if (this.state.showingInfoWindow) {
        this.setState({
          showingInfoWindow: false,
          activeMarker: null
        })
      }
    };

render() {

const style = {
 width: '100%',
 height: '400px',
 //marginBottom: '50px'
 }

const cigamAddress = () => {

   return (
      <div>
      <p>CIGAM GLOBAL </p>
      <p>Level 18, Park Ventures Ecoplex  </p>
      <p>57 Wireless Road, Lumpini, Pathumwan </p>
      <p>Bangkok 10330 Thailand</p>
   </div>
   )
}
//, marginBottom:'20px'
 return (
   <div className="" style={{height:'450px'}}> 
   <Map 
   google={this.props.google} 
   zoom={15}
   initialCenter={{
      lat: 13.743054,
      lng: 100.547844
   }}
   

  mapTypeControl={false}
  streetViewControl= {false}
  rotateControl= {false}
  fullscreenControl = {false}

  styles = {styles}

   style={style}>
   <Marker onClick={this.onMarkerClick}
    title={'CIGAM Global'}
    name={cigamAddress()}
    position={{lat: 13.743054, lng: 100.547844}}
   />
   <InfoWindow
          marker={this.state.activeMarker}
          visible={this.state.showingInfoWindow}>
            <div>
              <p>{this.state.selectedPlace.name}</p>
            </div>
        </InfoWindow>
   </Map>
   </div>
   );
   }
  }

export default GoogleApiWrapper({
 apiKey: ('AIzaSyCg15d-cqcwMDolVfqN0DxfHtlNTHdanXw')
})(googleMap);

