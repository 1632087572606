import React from 'react'

import { siteMetadata } from '../../gatsby-config'
import Layout from 'components/Layout'
import SEO from 'components/SEO'
import Contact from 'components/ContactForm'
import { injectIntl, FormattedMessage } from "gatsby-plugin-intl"
import Map from 'components/Map'

const ContactUsPage = ({ intl }) => {
  
  const isThai = () => {
      return (intl.locale === "th")
    }
  
  return (

    <Layout> 

          <SEO
            site={siteMetadata}
            title="Schedule a consultation"
            description="To schedule a consultation and take the first step towards your dream, please fill in this form and a coach will be in touch with as soon as possible. We would love to hear from you."
            
           />

      <section id="contactSection">
      <div className="container pt-5">
        <div className="row">
          <div className="col-lg-6 col-12">
            <h1><FormattedMessage id="c_schedule" /> </h1>
            <p1><FormattedMessage id="c_schedule_p" /> </p1>
          </div>
          <div className="col-lg-6 col-12">
           <Contact/>
            </div>
          </div>
      </div>
      </section>

      <section id="addressSection" className="">
        <div className="container">
          <div className="row">
          <div className="col-lg-4 col-12">
              <h1><FormattedMessage id="c_office" /></h1>
              <p><FormattedMessage id="c_office_1" /></p>
              <p><FormattedMessage id="c_office_2" /></p>
              <p><FormattedMessage id="c_office_3" /></p>
              <p><FormattedMessage id="c_office_4" /></p>
              { (isThai(intl)) && <p> <FormattedMessage id="c_office_5" /></p>}
              { (isThai(intl)) && <p> <FormattedMessage id="c_office_6" /></p>}
              <p><a href="mailto:info@cigamglobal.com">info@cigamglobal.com</a></p>
              <p>+66 2 309 3626</p>
          </div>
          <div className="col-lg-8 col-12 pl-0">
            <Map />
          </div>
          </div>
      </div>
    </section>
    
    </Layout>
  )
}

export default injectIntl(ContactUsPage)



 